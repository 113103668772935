import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgxEchartsModule} from 'ngx-echarts';
import {MatCardModule} from '@angular/material/card';
import * as echarts from 'echarts/core';
import {BarChart, LineChart, PieChart} from 'echarts/charts';
import {
  TitleComponent,
  TooltipComponent,
  GridComponent, LegendComponent,
} from 'echarts/components';
// Import the Canvas renderer, note that introducing the CanvasRenderer or SVGRenderer is a required step
import {
  CanvasRenderer
} from 'echarts/renderers';

import {ClientsCompletedComponent} from './components/basenji/clients-completed/clients-completed.component';
import {CirclePackingComponent} from './components/graph/circle-packing/circle-packing.component';
import {ForceLayoutComponent} from './components/graph/force-layout/force-layout.component';
import {GaugeComponent} from './components/graph/gauge/gauge.component';
import {HeatmapCartesianComponent} from './components/graph/heatmap-cartesian/heatmap-cartesian.component';
import {RadarComponent} from './components/graph/radar/radar.component';
import {SankeyComponent} from './components/graph/sankey/sankey.component';
import {TreeComponent} from './components/graph/tree/tree.component';
import {LandingComponent} from './components/landing/landing.component';
import {CompletedComponent} from './components/basenji/completed/completed.component';
import {NewComponent} from './components/basenji/new/new.component';
import {ClientsComponent} from './components/basenji/clients/clients.component';

echarts.use(
  [TitleComponent, TooltipComponent, GridComponent, LineChart, CanvasRenderer, BarChart, PieChart, LegendComponent]
);

@NgModule({
  declarations: [
    LandingComponent,
    CompletedComponent,
    NewComponent,
    ClientsComponent,
    ClientsCompletedComponent,
    CirclePackingComponent,
    ForceLayoutComponent,
    GaugeComponent,
    HeatmapCartesianComponent,
    RadarComponent,
    SankeyComponent,
    TreeComponent
  ],
  exports: [
    LandingComponent,
    CompletedComponent,
    NewComponent,
    ClientsComponent,
    ClientsCompletedComponent,
    CirclePackingComponent,
    ForceLayoutComponent,
    GaugeComponent,
    HeatmapCartesianComponent,
    RadarComponent,
    SankeyComponent,
    TreeComponent
  ],
  imports: [
    NgxEchartsModule.forRoot({echarts}),
    CommonModule,
    MatCardModule
  ]
})
export class HighLevelGraphingModule {
}
