import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SideNavigationComponent} from './components/side-navigation/side-navigation.component';
import {RouterModule} from '@angular/router';
import {MatListModule} from '@angular/material/list';
import {BrowserModule} from '@angular/platform-browser';
import {MenuManagementService} from '../../shared/services/menu/menu-management.service';
import { SideNavItemComponent } from './shared/components/side-nav-item/side-nav-item.component';
import {MatIconModule} from '@angular/material/icon';

@NgModule({
  declarations: [
    SideNavigationComponent,
    SideNavItemComponent
  ],
  exports: [
    SideNavigationComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatListModule,
    BrowserModule,
    MatIconModule,
  ],
  providers: [
    MenuManagementService
  ]
})
export class NavigationModule {
}
