import {Component, OnInit, ViewChild} from '@angular/core';
import {FlatTreeControl, NestedTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {CdkDragDrop, CdkDropList, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import {MatTable, MatTableDataSource} from '@angular/material/table';

// import clonedeep from 'lodash.clonedeep';

/**
 * Food data with nested structure.
 * Each node has a name and an optional list of children.
 */
interface FoodNode {
  name: string;
  position?: number;
  children?: FoodNode[];
}

const TREE_DATA: FoodNode[] = [
  {
    name: 'Fruit',
    position: 1,
    children: [
      {name: 'Apple'},
      {name: 'Banana'},
      {name: 'Fruit loops'},
    ]
  }, {
    name: 'Vegetables',
    position: 2,
    children: [
      {
        name: 'Green',
        children: [
          {name: 'Broccoli'},
          {name: 'Brussels sprouts'},
        ]
      }, {
        name: 'Orange',
        children: [
          {name: 'Pumpkins'},
          {name: 'Carrots'},
        ]
      },
    ]
  },
  {name: 'Broccoli'},
  {name: 'Brussels sprouts'},
];

/** Flat node with expandable and level information */
interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  level: number;
}


export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}


@Component({
  selector: 'app-manage-single-questionnaire',
  templateUrl: './manage-single-questionnaire.component.html',
  styleUrls: ['./manage-single-questionnaire.component.css']
})
export class ManageSingleQuestionnaireComponent implements OnInit {
  @ViewChild('table1') table1: MatTable<any>;
  @ViewChild('list1') list1: CdkDropList;
  displayedColumns: string[] = ['position', 'name', 'weight', 'symbol', 'level'];
  ELEMENT_DATA: PeriodicElement[];


  private _transformer = (node: FoodNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
      position: node.position
    };
  };

  treeControl = new FlatTreeControl<ExampleFlatNode>(
    node => node.level, node => node.expandable);

  treeFlattener = new MatTreeFlattener(
    this._transformer, node => node.level, node => node.expandable, node => node.children);

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  // dataSource1: MatTableDataSource<PeriodicElement> = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);


  constructor() {
  }

  // treeControl = new NestedTreeControl<FoodNode>(node => node.children);
  // dataSource = new MatTreeNestedDataSource<FoodNode>();
  activeNode: any;

  hasChild = (_: number, node: FoodNode) => !!node.children && node.children.length > 0;

  ngOnInit(): void {
    this.dataSource.data = TREE_DATA;
  }

  activateNode(node): boolean {
    // console.log(node);
    this.activeNode = node;
    return true;
  }

  todo = [
    'Get to work',
    'Pick up groceries',
    'Go home',
    'Fall asleep'
  ];

  done = [
    'Get up',
    'Brush teeth',
    'Take a shower',
    'Check e-mail',
    'Walk dog'
  ];

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
  }

  drop1(event: CdkDragDrop<string[]>) {
    console.log(event.container.data);
    console.log(event);

    // // @ts-ignore
    // event.container.data[event.currentIndex].position = event.previousIndex + 1;
    // // @ts-ignore
    // event.previousContainer.data.position = event.currentIndex;


    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }

    console.log(this.dataSource.data);

    // const levelCounter: number[] = [];
    for (let i = 0; i < event.previousContainer.data.length; i++) {
      // @ts-ignore
      // if (!levelCounter[event.previousContainer.data[i].level]) {
      //   // @ts-ignore
      //   levelCounter[event.previousContainer.data[i].level] = 0;
      // }
      // console.log(event.previousContainer.data[i]);
      // @ts-ignore
      // event.previousContainer.data[i].position = ++levelCounter[event.previousContainer.data[i].level];
      this.dataSource.data[i].position = i + 1;
    }

    // updates moved data and table, but not dynamic if more dropzones
    this.dataSource.data = this.dataSource.data;
    // this.dataSource2.data = clonedeep(this.dataSource2.data);
  }

  logRow(row: any): void {
    console.log(row);
  }
}
