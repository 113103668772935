<!--<mat-nav-list>-->
<!--  <a mat-list-item [routerLink]="[tenantId, 'p','basenji']">Basenji</a>-->
<!--  <a mat-list-item [routerLink]="[tenantId, 'p','sherloc']">Sherloc</a>-->
<!--  <a mat-list-item [routerLink]="[tenantId, 'p','beagle']">Beagle</a>-->
<!--  <a mat-list-item [routerLink]="[tenantId, 'p','whippet']">Whippet</a>-->
<!--  <a mat-list-item *ngIf="users" [routerLink]="[tenantId, 'users']">Users</a>-->
<!--  <a mat-list-item *ngIf="clients" [routerLink]="[tenantId, 'clients']">Clients</a>-->
<!--  <a mat-list-item *ngIf="subscription" [routerLink]="[tenantId, 'subscription']">Subscription</a>-->
<!--</mat-nav-list>-->

<mat-nav-list *ngFor="let singleNav of navigation; let singleNavIndex = index">
<!--  <a mat-list-item [routerLink]=singleNav.path>-->
<!--    {{singleNav.name}}-->
<!--  </a>-->
  <app-side-nav-item
    [menuItem]="singleNav">
  </app-side-nav-item>
</mat-nav-list>
