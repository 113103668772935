<div
  fxLayout="row wrap"
  fxFlexFill
  fxLayoutAlign="space-around stretch"
>

<!--  <app-clients-chart-->
<!--    style="margin: 5px;"-->
<!--    fxFlex="30"-->
<!--    fxFlex.lt-md="45"-->
<!--    fxFlex.lt-sm="100"-->
<!--  ></app-clients-chart>-->

<!--  <app-new-chart-->
<!--    style="margin: 5px;"-->
<!--    fxFlex="30"-->
<!--    fxFlex.lt-md="45"-->
<!--    fxFlex.lt-sm="100"-->
<!--  ></app-new-chart>-->

<!--  <app-clients-completed-chart-->
<!--    style="margin: 5px;"-->
<!--    fxFlex="30"-->
<!--    fxFlex.lt-md="80"-->
<!--    fxFlex.lt-sm="100"-->
<!--  ></app-clients-completed-chart>-->

</div>
