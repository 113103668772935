import {Injectable} from '@angular/core';
import {BehaviorSubject, Subscription} from 'rxjs';
import {AuthService} from '@auth0/auth0-angular';

@Injectable({
  providedIn: 'root'
})
export class TenantService {

  private CURRENT_TENANT: Tenant;
  public tenant$ = new BehaviorSubject<Tenant>(null);

  private AVAILABLE_TENANT: Tenant;
  public availableTenant$ = new BehaviorSubject<Tenant>(null);

  private authSubscription: Subscription;

  constructor(private auth: AuthService) {

    this.auth.user$.subscribe(user => {
      if (user) {
        console.log(user);
        let currentTenant: Tenant;
        // TODO: get the current tenant the user has selected. (This will be in the token)
        if (user['https://admin.basenji.wchsolutions.co.za/tenant']) {
          currentTenant = user['https://admin.basenji.wchsolutions.co.za/tenant'];
        }

        // TODO: Get all the tenants the current user has from a service
        let tenants: Tenant = {
          id: 'WCHA', name: 'WCH Administration', tenant_type: 'tenant',
          children: [
            {
              id: 'QDS', name: 'QDS', tenant_type: 'tenant'
            },
            {
              id: 'WCH', name: 'WCH Solutions', tenant_type: 'tenant',
              children: [
                {id: 'JTec', name: 'JTec', tenant_type: 'client'},
                {id: 'ALPHAPHARM', name: 'Alpha Pharm', tenant_type: 'client'},
                {id: 'JSOFT', name: 'JSOFT', tenant_type: 'client'},
              ]
            },
            {
              id: 'IPRG', name: 'Bjorn Test', tenant_type: 'tenant',
              children: [
                {
                  id: 'IPRGC', name: 'IPRG - Client', tenant_type: 'client'
                }
              ]
            }
          ]
        };
        if (user['https://admin.basenji.wchsolutions.co.za/parent_tenant']) {
          // currentTenant = user['https://admin.basenji.wchsolutions.co.za/parent_tenant'];
          tenants = processTenant(user['https://admin.basenji.wchsolutions.co.za/parent_tenant'].id, tenants);
        }

        function processTenant(master: string, tenant: Tenant): Tenant | undefined {
          if (tenant.id === master) {
            return tenant;
          }
          if (tenant.children) {
            for (const child of tenant.children) {
              return processTenant(master, child);
            }
          }
          return undefined;
        }

        if (currentTenant && currentTenant.children) {
          delete currentTenant.children;
          this.setCurrentTenant(currentTenant);
        } else {
          this.setCurrentTenant(currentTenant);
        }
        this.setAllTenants(tenants);
      }
    });
  }

  public setCurrentTenant(tenant: Tenant, update: boolean = false): void {
    // TODO: We need to tell the API, we have changed our selected Tenant;
    // TODO: We need to tell AUTH0 to refresh our token;
    this.CURRENT_TENANT = tenant;
    this.tenant$.next(tenant);

    this.auth.user$.subscribe(user => {

    });

  }

  public setAllTenants(tenants: Tenant): void {
    this.AVAILABLE_TENANT = tenants;
    this.availableTenant$.next(tenants);
  }

  public getCurrentTenantId(): string {
    return this.CURRENT_TENANT.id;
  }


}


export interface Tenant {
  name: string;
  id: string;
  tenant_type: 'tenant' | 'client';
  my_roles?: string[]; // TODO: Change this to a tenant role
  children?: Tenant[];
}
