<!--<mat-tree-->
<!--  [dataSource]="dataSource"-->
<!--  [treeControl]="treeControl"-->
<!--  class="example-tree"-->
<!--  cdkDropList-->
<!--  #questionList="cdkDropList"-->
<!--  [cdkDropListData]="this.dataSource.data"-->
<!--  (cdkDropListDropped)="drop($event)"-->
<!--&gt;-->
<!--  &lt;!&ndash; This is the tree node template for leaf nodes &ndash;&gt;-->
<!--  &lt;!&ndash; There is inline padding applied to this node using styles.-->
<!--    This padding value depends on the mat-icon-button width. &ndash;&gt;-->
<!--  <mat-tree-node-->
<!--    *matTreeNodeDef="let node" matTreeNodeToggle-->
<!--    (click)="activateNode(node)"-->
<!--    cdkDrag-->
<!--  >-->
<!--    <mat-icon-->
<!--      class="mat-icon-rtl-mirror"-->
<!--      cdkDragHandle-->
<!--    >-->
<!--      drag_indicator-->
<!--    </mat-icon>-->
<!--    {{node.name}}-->
<!--  </mat-tree-node>-->
<!--  &lt;!&ndash; This is the tree node template for expandable nodes &ndash;&gt;-->
<!--  <mat-nested-tree-node-->
<!--    *matTreeNodeDef="let node; when: hasChild"-->
<!--    cdkDrag-->
<!--  >-->
<!--    <div-->
<!--      class="mat-tree-node"-->
<!--      (click)="activateNode(node)"-->
<!--      [ngClass]="{ 'background-highlight': activeNode ? activeNode.name === node.name : false }"-->
<!--      cdkDropList-->
<!--      (cdkDropListDropped)="drop($event)"-->
<!--    >-->
<!--      <mat-icon-->
<!--        class="mat-icon-rtl-mirror"-->
<!--        cdkDragHandle-->
<!--      >-->
<!--        drag_indicator-->
<!--      </mat-icon>-->
<!--      <button mat-icon-button matTreeNodeToggle-->
<!--              [attr.aria-label]="'Toggle ' + node.name"-->
<!--              (click)="activateNode(node)"-->
<!--      >-->
<!--        <mat-icon class="mat-icon-rtl-mirror">-->
<!--          {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}-->
<!--        </mat-icon>-->
<!--      </button>-->
<!--      {{node.name}}-->
<!--    </div>-->

<!--    &lt;!&ndash; There is inline padding applied to this div using styles.-->
<!--        This padding value depends on the mat-icon-button width.  &ndash;&gt;-->
<!--    <div [class.example-tree-invisible]="!treeControl.isExpanded(node)"-->
<!--         role="group">-->
<!--      <ng-container matTreeNodeOutlet></ng-container>-->
<!--    </div>-->

<!--  </mat-nested-tree-node>-->
<!--</mat-tree>-->


<div class="example-container">
  <h2>To do</h2>

  <div
    cdkDropList
    #todoList="cdkDropList"
    [cdkDropListData]="todo"
    [cdkDropListConnectedTo]="[doneList]"
    class="example-list"
    (cdkDropListDropped)="drop($event)"
  >
    <div class="example-box" *ngFor="let item of todo" cdkDrag>
      <mat-icon
        class="mat-icon-rtl-mirror"
        cdkDragHandle
      >
        drag_indicator
      </mat-icon>
      {{item}}
    </div>
  </div>
</div>

<div class="example-container">
  <h2>Done</h2>

  <mat-list
    role="list"
    cdkDropList
    #doneList="cdkDropList"
    [cdkDropListData]="done"
    [cdkDropListConnectedTo]="[todoList]"
    (cdkDropListDropped)="drop($event)"
  >
    <mat-list-item
      *ngFor="let item of done" cdkDrag
      role="listitem"
    >
      <mat-icon
        class="mat-icon-rtl-mirror"
        cdkDragHandle
      >
        drag_indicator
      </mat-icon>
      {{item}}
    </mat-list-item>
  </mat-list>
</div>


<mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
  <!-- This is the tree node template for leaf nodes -->
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
    <!-- use a disabled button to provide padding for tree leaf -->
    <button mat-icon-button disabled></button>
    {{node.name}}
  </mat-tree-node>
  <!-- This is the tree node template for expandable nodes -->
  <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding>
    <button mat-icon-button matTreeNodeToggle
            [attr.aria-label]="'Toggle ' + node.name">
      <mat-icon class="mat-icon-rtl-mirror">
        {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
      </mat-icon>
    </button>
    {{node.name}}
  </mat-tree-node>
</mat-tree>


<mat-table [dataSource]="dataSource"
           class="mat-elevation-z8" cdkDropList #table1 #list1="cdkDropList"
           [cdkDropListData]="dataSource.data"
           (cdkDropListDropped)="drop1($event)" id="table1"
>

  <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->

  <!-- Position Column -->
  <ng-container matColumnDef="position">
    <mat-header-cell *matHeaderCellDef> No.</mat-header-cell>
    <mat-cell *matCellDef="let element"> {{element.position}} </mat-cell>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef> Name</mat-header-cell>
    <mat-cell *matCellDef="let element"> {{element.name}} </mat-cell>
  </ng-container>

  <!-- Weight Column -->
  <ng-container matColumnDef="weight">
    <mat-header-cell *matHeaderCellDef> Weight</mat-header-cell>
    <mat-cell *matCellDef="let element"> {{element.weight}} </mat-cell>
  </ng-container>

  <!-- Symbol Column -->
  <ng-container matColumnDef="symbol">
    <mat-header-cell *matHeaderCellDef> Symbol</mat-header-cell>
    <mat-cell *matCellDef="let element"> {{element.symbol}} </mat-cell>
  </ng-container>

  <!-- Symbol Column -->
  <ng-container matColumnDef="level">
    <mat-header-cell *matHeaderCellDef>Level</mat-header-cell>
    <mat-cell *matCellDef="let element"> {{element.level}} </mat-cell>
  </ng-container>

  <mat-header-row
    *matHeaderRowDef="displayedColumns"
  >

  </mat-header-row>
  <mat-row
    *matRowDef="let row; columns: displayedColumns;"
    cdkDrag
    (click)="logRow(row)"
  ></mat-row>
</mat-table>
