import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Subject} from 'rxjs';
import {AppConfigService} from '../../../../services/app-config.service';

@Injectable({
  providedIn: 'root'
})
export class QuestionnaireService {
  constructor(private http: HttpClient) {
    console.log(AppConfigService.settings);
  }

  private completedQuestionnaire = new Subject<any>();
  completedQuestionnaire$ = this.completedQuestionnaire.asObservable();

  setCompletedQuestionnaire(newCompletedQuestionnaire): void {
    this.completedQuestionnaire.next(newCompletedQuestionnaire);
  }

  getMyCompletedQuestionnaires = () => this.http.get(`${AppConfigService.settings.basenjiAPIURL}/admin/data/questionnaire`)
    .subscribe((result) => {
      console.log(result);
      this.setCompletedQuestionnaire(result);
    })
}
