<!--<mat-grid-list cols="4" rowHeight="100px">-->
<!--  <mat-grid-tile-->
<!--    [colspan]="1"-->
<!--    [rowspan]="1"-->
<!--    [style.background]="'lightblue'"-->
<!--  >-->
<!--    Graph 1-->
<!--  </mat-grid-tile>-->
<!--  <mat-grid-tile-->
<!--    [colspan]="1"-->
<!--    [rowspan]="1"-->
<!--    [style.background]="'lightblue'"-->
<!--  >-->
<!--    Graph 2-->
<!--  </mat-grid-tile>-->
<!--  <mat-grid-tile-->
<!--    [colspan]="1"-->
<!--    [rowspan]="1"-->
<!--    [style.background]="'lightblue'"-->
<!--  >-->
<!--    Graph 3-->
<!--  </mat-grid-tile>-->
<!--  <mat-grid-tile-->
<!--    [colspan]="1"-->
<!--    [rowspan]="1"-->
<!--    [style.background]="'lightblue'"-->
<!--  >-->
<!--    Graph 4-->
<!--  </mat-grid-tile>-->

<!--  <mat-grid-tile-->
<!--    [colspan]="4"-->
<!--    [rowspan]="1"-->
<!--    [style.background]="'lightblue'"-->
<!--  >-->
<!--    Management-->
<!--  </mat-grid-tile>-->

<!--  <mat-grid-tile-->
<!--    [colspan]="4"-->
<!--    [rowspan]="1"-->
<!--    [style.background]="'lightblue'"-->
<!--  >-->
<!--    Reporting-->
<!--  </mat-grid-tile>-->


<!--  <mat-grid-tile-->
<!--    *ngFor="let tile of tiles"-->
<!--    [colspan]="tile.cols"-->
<!--    [rowspan]="tile.rows"-->
<!--    [style.background]="tile.color">-->
<!--    {{tile.text}}-->
<!--  </mat-grid-tile>-->
<!--</mat-grid-list>-->

<!--<div fxLayout="row">-->
<!--  <div>-->
<!--    HI THERE-->
<!--  </div>-->
<!--  <div-->
<!--      *ngFor="let tile of tiles"-->
<!--      [style.background]="tile.color">-->
<!--    {{tile.text}}-->
<!--  </div>-->

<!--</div>-->
<div fxLayout.lt-sm="row"
     fxLayout="column"
     fxLayoutAlign="space-evenly stretch"
>
  <!--    fxLayoutGap="16px grid"-->
  <div
    fxLayout.lt-md="row"
    fxLayout="row wrap"
    fxLayoutGap="grid"
    fxLayoutAlign="space-evenly"
  >
    <img [routerLink]="['home']" src="https://via.placeholder.com/200x60?text=Basenji"/>
    <img src="https://via.placeholder.com/200x60?text=Manage+Client"/>
    <img [routerLink]="['manage', 'q']" src="https://via.placeholder.com/200x60?text=Manage+Questionnaire"/>
    <img src="https://via.placeholder.com/200x60?text=Report+Client"/>
    <img src="https://via.placeholder.com/200x60?text=Report+Compeleted"/>
  </div>

  <router-outlet></router-outlet>

</div>
