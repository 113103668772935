import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AuthGuard} from '@auth0/auth0-angular';
import {ErrorComponent} from '../components/error/error.component';
import {BasenjiModule} from '../modules/basenji/basenji.module';
import {ProfileComponent} from '../components/user/profile/profile.component';

const routes: Routes = [
  {
    path: 'nav',
    component: ErrorComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'error',
    component: ErrorComponent,
    canActivate: [AuthGuard],
  },
  {
    path: ':tenantId',
    canActivate: [AuthGuard],
    children: [
      // All Products
      {
        path: 'p',
        children: [
          {
            path: 'basenji',
            loadChildren: () => import('../modules/basenji/basenji.module').then(m => m.BasenjiModule)
            // loadChildren: () => BasenjiModule
          },
          {
            path: 'sherloc',
            loadChildren: () => import('../modules/sherloc/sherloc.module').then(m => m.SherlocModule)
          },
          {
            path: 'beagle',
            loadChildren: () => import('../modules/beagle/beagle.module').then(m => m.BeagleModule)
          },
          {
            path: 'whippet',
            loadChildren: () => import('../modules/whippet/whippet.module').then(m => m.WhippetModule)
          },
        ]
      },
      // Tenant Management
      {
        path: 'users',
        loadChildren: () => import('../modules/whippet/whippet.module').then(m => m.WhippetModule)
      },
      {
        path: 'clients', // Clients are tenants that the current tenant has access to.
        loadChildren: () => import('../modules/whippet/whippet.module').then(m => m.WhippetModule)
      },
      {
        path: 'subscription',
        loadChildren: () => import('../modules/whippet/whippet.module').then(m => m.WhippetModule)
      },
    ]
  },
  {
    path: 'ga',
    canActivate: [AuthGuard],
    children: [
      // {path: '', redirectTo: 'tracks'},
      {path: 'basenji', component: BasenjiModule},
    ]
  },
  {
    path: 'u',
    canActivate: [AuthGuard],
    // redirectTo: 'profile',
    children: [
      // This is the current users profile
      {path: 'profile', component: ProfileComponent},
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'})],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
