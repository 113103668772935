<ng-container *ngIf="auth.isLoading$ | async; else notLoading">

</ng-container>
<ng-template #notLoading>
  <ng-container *ngIf="auth.isAuthenticated$ | async; else loggedOut">
  <span *ngIf="currentTenant && availableTenants; else singleTenant">

    <button [matMenuTriggerFor]="tenantSelection" mat-button>
      {{currentTenant.name}}
      <mat-icon>more_vert</mat-icon>
    </button>
    <span class="spacer"></span>
    <mat-menu #tenantSelection="matMenu">
        <app-tenant-selection
          [activeTenantID]="currentTenant.id"
          [tenant]="availableTenants"
        >
        </app-tenant-selection>
    </mat-menu>
    <span class="spacer"></span>
  </span>
    <span *ngIf="displayName" [matMenuTriggerFor]="menu">{{displayName}}</span>
    <button [matMenuTriggerFor]="menu"
            aria-label="user profile and options menu"
            mat-icon-button
    >
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button (click)="auth.logout({ returnTo: document.location.origin })" aria-label="logout"
              mat-menu-item
      >
        <mat-icon>logout</mat-icon>
        <span>Logout</span>
      </button>
      <!--    <button mat-menu-item>-->
      <!--      <mat-icon>dialpad</mat-icon>-->
      <!--      <span>Redial</span>-->
      <!--    </button>-->
      <!--    <button mat-menu-item disabled>-->
      <!--      <mat-icon>voicemail</mat-icon>-->
      <!--      <span>Check voice mail</span>-->
      <!--    </button>-->
      <!--    <button mat-menu-item>-->
      <!--      <mat-icon>notifications_off</mat-icon>-->
      <!--      <span>Disable alerts</span>-->
      <!--    </button>-->
    </mat-menu>

  </ng-container>

  <ng-template #loggedOut>
    <!--  <button (click)="loginWithRedirect()">Log in With Redirect</button>-->
    <button (click)="loginWithPopup()">Log in POP</button>
    <button (click)="loginWithRedirect()">Log in Red</button>
    <button (click)="getAccessToken()">Token S</button>
    <button (click)="getAccessToken()">Token P</button>
  </ng-template>

  <ng-template #singleTenant>
    <!--  <button (click)="loginWithRedirect()">Log in With Redirect</button>-->
    <span *ngIf="currentTenant">{{currentTenant.name}}</span>
  </ng-template>

</ng-template>


