import {Component, OnInit} from '@angular/core';

export interface Tile {
  color: string;
  cols: number;
  rows: number;
  text: string;
}

@Component({
  selector: 'app-basenji-home',
  templateUrl: './basenji-home.component.html',
  styleUrls: ['./basenji-home.component.css']
})
export class BasenjiHomeComponent implements OnInit {

  constructor() {
    console.log('BasenjiHomeComponent');
  }

  tiles: Tile[] = [
    {text: 'Graphs', cols: 1, rows: 1, color: 'lightblue'},
    {text: 'Graphs', cols: 1, rows: 1, color: 'lightblue'},
    {text: 'Graphs', cols: 1, rows: 1, color: 'lightblue'},
    {text: 'Graphs', cols: 1, rows: 1, color: 'lightblue'},
    {text: 'Management', cols: 4, rows: 1, color: 'lightblue'},
    {text: 'Reporting', cols: 4, rows: 1, color: 'lightblue'},
    // {text: 'One', cols: 1, rows: 1, color: 'lightblue'},
    // {text: 'One', cols: 1, rows: 1, color: 'lightblue'},
    // {text: 'One', cols: 1, rows: 1, color: 'lightblue'},
    // {text: 'Two', cols: 1, rows: 2, color: 'lightgreen'},
    // {text: 'Three', cols: 1, rows: 1, color: 'lightpink'},
    // {text: 'Four', cols: 2, rows: 1, color: '#DDBDF1'},
  ];

  ngOnInit(): void {

  }
}
