import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {Menu} from 'shared/dist/types/Menu';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Router} from '@angular/router';

@Component({
  selector: 'app-side-nav-item',
  templateUrl: './side-nav-item.component.html',
  styleUrls: ['./side-nav-item.component.scss'],
  animations: [
    trigger('indicatorRotate', [
      state('collapsed', style({transform: 'rotate(0deg)'})),
      state('expanded', style({transform: 'rotate(180deg)'})),
      transition('expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
      ),
    ])
  ]
})
export class SideNavItemComponent implements OnInit {
  expanded = false;
  @HostBinding('attr.aria-expanded') ariaExpanded = this.expanded;
  @Input() depth: number;
  @Input() menuItem: Menu;

  constructor(public router: Router) {
    if (this.depth === undefined) {
      this.depth = 0;
    }
  }

  ngOnInit(): void {
  }

  // onItemSelected(item: Menu): void {
  //   if (!item.children || !item.children.length) {
  //     this.router.navigate([item.route]);
  //     // this.navService.closeNav();
  //   }
  //   if (item.children && item.children.length) {
  //     this.expanded = !this.expanded;
  //   }
  // }

  onItemSelected(menuItem: Menu): boolean {
    // if (!menuItem.children || !menuItem.children.length) {
    //   this.router.navigate([menuItem.route]);
    //   this.navService.closeNav();
    // }
    // if (item.children && item.children.length) {
    //   this.expanded = !this.expanded;
    // }
    menuItem.expanded = !menuItem.expanded;
    // At this point, we should do something to check if the menu item has sub items.
    return true;
  }
}
