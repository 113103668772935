import {AfterViewInit, Component, OnInit, ViewChild, OnDestroy} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {QuestionnaireService} from '../../../services/management/questionnaire.service';
import {Subscription} from 'rxjs';
import {AppConfigService} from '../../../../../services/app-config.service';


@Component({
  selector: 'app-questionnaire',
  templateUrl: './questionnaire.component.html',
  styleUrls: ['./questionnaire.component.css']
})
export class QuestionnaireComponent implements OnInit, AfterViewInit, OnDestroy {
  displayedColumns: string[] = ['created_by', 'created_date', 'client', 'questionnaire_id', 'completed', 'url'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  questionnaireServiceSubscription: Subscription;
  knownColumns: string[] = [];

  constructor(private questionnaireService: QuestionnaireService) {
  }

  ngAfterViewInit(): void {
    // this.dataSource.paginator = this.paginator;
    // this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  ngOnInit(): void {
    this.questionnaireService.getMyCompletedQuestionnaires();
    this.questionnaireServiceSubscription = this.questionnaireService.completedQuestionnaire$.subscribe(completedQuestionnaires => {
      if (completedQuestionnaires && completedQuestionnaires.fields && completedQuestionnaires.rows) {
        // console.log(this.displayedColumns);
        // console.table(completedQuestionnaires.rows);
        this.dataSource = new MatTableDataSource(completedQuestionnaires.rows.map(row => {
          let url: string = AppConfigService.settings.basenjiURL;
          if (row.client) {
            url += `/${row.client}`;
          }
          url += `/q/${row.questionnaire_id}/quuid/${row.questionnaire_completion_uuid}`;
          return {
            ...row,
            url,
          };
        }));

      }
    });
  }

  ngOnDestroy(): void {
    if (this.questionnaireServiceSubscription) {
      this.questionnaireServiceSubscription.unsubscribe();
    }
  }
}
