<a [ngStyle]="{'padding-left': (depth * 12) + 'px'}"
   mat-list-item
   [routerLinkActive]="menuItem.link ? 'is_active' : 'is_disabled'"
   [routerLink]="menuItem.link ? menuItem.path : null"
   [queryParams]="menuItem.link ? menuItem.queryParams : null"
   [ngClass]="{'active': menuItem.path.join('/') ? router.isActive(menuItem.path.join('/'), false): false, 'expanded': !!menuItem.expanded}"
   (click)="onItemSelected(menuItem)"
>


  <mat-icon *ngIf="menuItem.icon" class="routeIcon">{{menuItem.icon}}</mat-icon>
  {{menuItem.name}}
  <span fxFlex *ngIf="menuItem.children && menuItem.children.length">
    <span fxFlex></span>
    <mat-icon [@indicatorRotate]="!!menuItem.expanded ? 'expanded': 'collapsed'">
      expand_more
    </mat-icon>
  </span>
  <!--  -> {{router.isActive(menuItem.path.join('/'), true)}} -> {{expanded}}-->

</a>
<div *ngIf="!!menuItem.expanded">
  <app-side-nav-item *ngFor="let child of menuItem.children" [menuItem]="child" [depth]="depth+1">
  </app-side-nav-item>
</div>
<!--<a mat-list-item [ngStyle]="{'padding-left': (depth * 12) + 'px'}" (click)="onItemSelected(item)"-->
<!--              [ngClass]="{'active': item.route ? router.isActive(item.route, true): false, 'expanded': expanded}">-->
<!--  <mat-icon class="routeIcon">{{item.iconName}}</mat-icon>-->
<!--  {{item.displayName}}-->
<!--  <span fxFlex *ngIf="item.children && item.children.length">-->
<!--    <span fxFlex></span>-->
<!--    <mat-icon [@indicatorRotate]="expanded ? 'expanded': 'collapsed'">-->
<!--      expand_more-->
<!--    </mat-icon>-->
<!--  </span>-->
<!--</a>-->
<!--<div *ngIf="expanded">-->
<!--  <app-menu-list-item *ngFor="let child of item.children" [item]="child" [depth]="depth+1">-->
<!--  </app-menu-list-item>-->
<!--</div>-->
