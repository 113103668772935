import {NgModule, APP_INITIALIZER} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {FlexLayoutModule} from '@angular/flex-layout';
import {AuthHttpInterceptor, AuthModule} from '@auth0/auth0-angular';
import {MenuComponent} from './shared/component/auth/menu/menu.component';
import {ErrorComponent} from './components/error/error.component';
import {LayoutModule} from '@angular/cdk/layout';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatButtonModule} from '@angular/material/button';
import {MatMenuModule} from '@angular/material/menu';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatIconModule} from '@angular/material/icon';
import {AppRoutingModule} from './app-routing/app-routing.module';
import {ProfileComponent} from './components/user/profile/profile.component';
import {AppConfigService} from './services/app-config.service';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {NavigationModule} from './modules/navigation/navigation.module';
import {MenuManagementService} from './shared/services/menu/menu-management.service';
import { TenantSelectionComponent } from './shared/component/tenant/tenant-selection/tenant-selection.component';

export const initializeApp = (appConfigService: AppConfigService) => (): Promise<any> => {
  return appConfigService.load();
};

@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    ErrorComponent,
    ProfileComponent,
    TenantSelectionComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    // MatSliderModule,
    FlexLayoutModule,
    // AuthModule.forRoot({
    //   domain: config.auth.domain,
    //   clientId: config.auth.clientId,
    // }),
    AuthModule.forRoot(),
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatMenuModule,
    AppRoutingModule,
    HttpClientModule,
    NavigationModule,
  ],
  providers: [
    AppConfigService,
    MenuManagementService,
    {provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true},
    {provide: APP_INITIALIZER, useFactory: initializeApp, deps: [AppConfigService], multi: true}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
