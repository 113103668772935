import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {AuthService} from '@auth0/auth0-angular';
import {DOCUMENT} from '@angular/common';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {Observable} from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';
import {MatSidenav} from '@angular/material/sidenav';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  @ViewChild('drawer') sidenav: MatSidenav;
  drawer: HTMLElement;

  constructor(@Inject(DOCUMENT) public document: Document, public auth: AuthService, private breakpointObserver: BreakpointObserver) {
  }

  title = 'WChAdmin';
  isLoading = true;
  authenticated = false;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  showFiller = false;


  // reason = '';
  //
  // close(reason: string) {
  //   this.reason = reason;
  //   this.sidenav.close();
  // }
  //
  // shouldRun = true;

  ngOnInit(): void {
    // this.auth.getAccessTokenSilently().subscribe(token => {
    //   console.log('getAccessTokenSilently');
    //   console.log(token);
    // });

    this.auth.isLoading$.subscribe(loading => {
      console.log('Is loading: ', loading);
      this.isLoading = loading;
    });

    this.auth.isAuthenticated$.subscribe(authenticated => {
      console.log('isAuthenticated', authenticated);
      this.authenticated = authenticated;
    });
    // this.auth.s
  }

  // mode = new FormControl('over');
  shouldRun = true;
}
