import {Injectable} from '@angular/core';
import {AuthService} from '@auth0/auth0-angular';

@Injectable({
  providedIn: 'root'
})
export class AuthRequestsService {

  constructor(private auth: AuthService) {
  }

  public refreshCurrentToken(): void {
    const tokenDataSilent = this.auth
      .getAccessTokenSilently()
      .subscribe((token) => {
        console.log('We got the token');
        console.log(token);
      }, () => {
        console.log('There was an error trying to get the access token silently, we are not going to push further');
        // const tokenDataPopup = this.auth.getAccessTokenWithPopup().subscribe(() => {
        // }, () => {
        //   console.log('There was an error trying to get the access token with popup');
        // }, () => {
        //   if (tokenDataSilent) {
        //     tokenDataSilent.unsubscribe();
        //   }
        //   if (tokenDataPopup) {
        //     tokenDataPopup.unsubscribe();
        //   }
        // });
        if (tokenDataSilent) {
          tokenDataSilent.unsubscribe();
        }
      });

  }
}
