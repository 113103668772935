import {Component, Input, OnInit} from '@angular/core';
import {Tenant, TenantService} from '../../../services/tenant/tenant.service';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-tenant-selection',
  templateUrl: './tenant-selection.component.html',
  styleUrls: ['./tenant-selection.component.scss'],
  animations: [
    trigger('indicatorRotate', [
      state('collapsed', style({transform: 'rotate(0deg)'})),
      state('expanded', style({transform: 'rotate(180deg)'})),
      transition('expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
      ),
    ])
  ]
})
export class TenantSelectionComponent implements OnInit {
  expanded = false;

  @Input() tenant: Tenant;
  @Input() activeTenantID: string;
  @Input() depth: number;

  constructor(private tenantService: TenantService) {
    if (this.depth === undefined) {
      this.depth = 0;
    }
  }

  ngOnInit(): void {

    // console.log(this.tenant);
  }

  changeCurrentTenant(newSelectedTenant: Tenant): void {
    this.tenantService.setCurrentTenant(newSelectedTenant);
  }

}
