<!--<button [ngClass]="{'active': tenant.id === activeTenantID}"-->
<!--        [ngStyle]="{'padding-left': (depth * 12) + 'px'}"-->
<!--        aria-label="logout"-->
<!--        mat-menu-item-->
<!--&gt;-->
<!--  <mat-icon>{{tenant.tenant_type}}</mat-icon>-->
<!--  <span>{{tenant.name}}</span>-->
<!--  <span *ngIf="tenant.children && tenant.children.length" fxFlex>-->
<!--    <span fxFlex></span>-->
<!--    <mat-icon [@indicatorRotate]="!!expanded ? 'expanded': 'collapsed'">-->
<!--      expand_more-->
<!--    </mat-icon>-->
<!--  </span>-->
<!--</button>-->
<!--<div *ngIf="!!expanded && tenant.children">-->
<!--  <app-tenant-selection-->
<!--    *ngFor="let child of tenant.children"-->
<!--    [activeTenantID]="activeTenantID"-->
<!--    [depth]="depth+1"-->
<!--    [tenant]="child"-->
<!--  >-->
<!--  </app-tenant-selection>-->
<!--</div>-->

<!--<button mat-menu-item [matMenuTriggerFor]="vertebrates">Vertebrates</button>-->

<!--<mat-menu #vertebrates="matMenu">-->
<!--  <button mat-menu-item [matMenuTriggerFor]="fish">Fishes</button>-->
<!--  <button mat-menu-item [matMenuTriggerFor]="amphibians">Amphibians</button>-->
<!--  <button mat-menu-item [matMenuTriggerFor]="reptiles">Reptiles</button>-->
<!--  <button mat-menu-item>Birds</button>-->
<!--  <button mat-menu-item>Mammals</button>-->
<!--</mat-menu>-->

<!--<mat-icon-->
<!--  (click)="changeCurrentTenant(tenant)"-->
<!--&gt;-->
<!--  {{-->
<!--  tenant.id === activeTenantID ?-->
<!--    'radio_button_checked' :-->
<!--    'radio_button_unchecked'-->
<!--  }}-->
<!--</mat-icon>-->

<span
  *ngIf="tenant.children && tenant.children.length > 0; else buttonNoChild"
>
  <button
    [matMenuTriggerFor]="tenantChild"
    [ngClass]="{'active': tenant.id === activeTenantID}"
    mat-menu-item
  >
    <mat-icon
      (click)="changeCurrentTenant(tenant)"
    >
      {{
      tenant.id === activeTenantID ?
        'radio_button_checked' :
        'radio_button_unchecked'
      }}
    </mat-icon>

    <mat-icon>{{tenant.tenant_type === 'tenant' ? 'domain' : 'people'}}</mat-icon>
    <span>{{tenant.name}}</span>
  </button>

  <mat-menu #tenantChild="matMenu"
            yPosition="below"
  >
      <app-tenant-selection
        *ngFor="let child of tenant.children"
        [activeTenantID]="activeTenantID"
        [depth]="depth+1"
        [tenant]="child"
      >
      </app-tenant-selection>
  </mat-menu>

</span>

<ng-template #buttonNoChild>
  <button
    (click)="changeCurrentTenant(tenant)"
    [ngClass]="{'active': tenant.id === activeTenantID}"
    mat-menu-item
  >
    <mat-icon
    >
      {{
      tenant.id === activeTenantID ?
        'radio_button_checked' :
        'radio_button_unchecked'
      }}
    </mat-icon>

    <mat-icon>{{tenant.tenant_type === 'tenant' ? 'domain' : 'people'}}</mat-icon>
    <span>{{tenant.name}}</span>
  </button>

</ng-template>
