<form [formGroup]="questionnaireForm" (ngSubmit)="onSubmit()">
  <label>
    Questionnaire Name:
    <input type="text" formControlName="name" required>
  </label>
</form>

<p>
  Form Value: {{ questionnaireForm.value | json }}
</p>


<p>
  Form Status: {{ questionnaireForm.status }}
</p>

<p>
  <button (click)="updateTest()">Update Profile</button>
</p>
