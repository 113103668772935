<mat-sidenav-container class="sidenav-container" *ngIf="!isLoading && authenticated">
  <mat-sidenav
    #drawer
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="(isHandset$ | async) === false"
  >
    <mat-toolbar>Menu</mat-toolbar>
    <app-side-navigation></app-side-navigation>
  </mat-sidenav>
<!--  <mat-sidenav opened mode="side" position="end">End content</mat-sidenav>-->
  <mat-sidenav-content>
    <mat-toolbar color="primary">
      <span>
        <button (click)="drawer ? drawer.toggle(): true" *ngIf="!(isHandset$ | async)" aria-label="icon-button with menu icon"
                class="example-icon"
                mat-icon-button>
        <mat-icon>menu</mat-icon>
      </button>
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer ? drawer.toggle(): true"
        *ngIf="isHandset$ | async"
      >
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      </span>

      <span>WCH Admin</span>
      <span class="spacer"></span>
      <app-auth-login-menu></app-auth-login-menu>

    </mat-toolbar>
    <!-- Add Content Here -->
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>

<div *ngIf="isLoading">
  Loading...
</div>


<mat-sidenav-container class="sidenav-container" *ngIf="!isLoading && !authenticated">
  <mat-sidenav-content>
    <mat-toolbar color="primary">
        <span>WCH Admin</span>
      <span class="spacer"></span>
      <app-auth-login-menu></app-auth-login-menu>

    </mat-toolbar>
    <!-- Add Content Here -->
    <div>
<!--      ToDo: Add notes here about this-->
      You need to login to get more information
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

<!--<mat-drawer-container class="example-container" autosize>-->
<!--  <mat-drawer #drawer class="example-sidenav" mode="side">-->
<!--    <p>Auto-resizing sidenav</p>-->
<!--    <p *ngIf="showFiller">Lorem, ipsum dolor sit amet consectetur.</p>-->
<!--    <button (click)="showFiller = !showFiller" mat-raised-button>-->
<!--      Toggle extra text-->
<!--    </button>-->
<!--  </mat-drawer>-->

<!--  <div class="example-sidenav-content">-->
<!--    <button type="button" mat-button (click)="drawer.toggle()">-->
<!--      Toggle sidenav-->
<!--    </button>-->
<!--  </div>-->

<!--</mat-drawer-container>-->


<!--<mat-sidenav-container-->
<!--  class="example-container" (backdropClick)="close('backdrop')" *ngIf="shouldRun">-->
<!--  <mat-sidenav #sidenav (keydown.escape)="close('escape')" disableClose>-->
<!--    <p>-->
<!--      <button mat-button (click)="close('toggle button')">Toggle</button>-->
<!--    </p>-->
<!--  </mat-sidenav>-->

<!--  <mat-sidenav-content>-->
<!--    <p>-->
<!--      <button mat-button (click)="sidenav.open()">Open</button>-->
<!--    </p>-->
<!--    <p>Closed due to: {{reason}}</p>-->
<!--  </mat-sidenav-content>-->
<!--</mat-sidenav-container>-->


<!--<mat-sidenav-container class="example-container">-->
<!--  <mat-sidenav #sidenav [mode]="mode.value">-->
<!--    <p>-->
<!--      <button mat-button (click)="sidenav.toggle()">Toggle</button>-->
<!--    </p>-->
<!--    <p>-->
<!--      <mat-radio-group class="example-radio-group" [formControl]="mode">-->
<!--        <label>Mode:</label>-->
<!--        <mat-radio-button value="over">Over</mat-radio-button>-->
<!--        <mat-radio-button value="side">Side</mat-radio-button>-->
<!--        <mat-radio-button value="push">Push</mat-radio-button>-->
<!--      </mat-radio-group>-->
<!--    </p>-->
<!--  </mat-sidenav>-->

<!--  <mat-sidenav-content>-->
<!--    <p>-->
<!--      <button mat-button (click)="sidenav.toggle()">Toggle</button>-->
<!--    </p>-->
<!--    <p>-->
<!--      <mat-radio-group class="example-radio-group" [formControl]="mode">-->
<!--        <label>Mode:</label>-->
<!--        <mat-radio-button value="over">Over</mat-radio-button>-->
<!--        <mat-radio-button value="side">Side</mat-radio-button>-->
<!--        <mat-radio-button value="push">Push</mat-radio-button>-->
<!--      </mat-radio-group>-->
<!--    </p>-->
<!--  </mat-sidenav-content>-->
<!--</mat-sidenav-container>-->
