import {Component, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from '@auth0/auth0-angular';
import {DOCUMENT} from '@angular/common';
import {Observable, Subscription} from 'rxjs';
import {AppConfigService} from '../../../../services/app-config.service';
import {Tenant, TenantService} from 'src/app/shared/services/tenant/tenant.service';
import {error} from 'protractor';
import {AuthRequestsService} from '../../../services/auth/auth-requests.service';

@Component({
  selector: 'app-auth-login-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit, OnDestroy {
  @Input() autoLogin = true;

  user: import('@auth0/auth0-spa-js').User | null = null;
  displayName: string;
  currentTenant: Tenant;
  availableTenants: Tenant;

  private currentTenantSubscription: Subscription;
  private availableTenantSubscription: Subscription;
  private userSubscription: Subscription;

  constructor(@Inject(DOCUMENT) public document: Document,
              public auth: AuthService,
              private tenantService: TenantService,
              private authRequestsService: AuthRequestsService
  ) {
    // this.auth.isLoading$.subscribe(loading => {
    //     if (!loading) {
    //       this.auth.getAccessTokenSilently().subscribe(value => {
    //           console.log('getAccessTokenSilently', value);
    //         },
    //         // tslint:disable-next-line:no-shadowed-variable
    //         error => {
    //           console.log('getAccessTokenSilently error: ', error);
    //         });
    //     }
    //   },
    //   // tslint:disable-next-line:no-shadowed-variable
    //   error => {
    //     console.log('isLoading$ error: ', error);
    //   });
  }

  loginWithRedirect(redirectPath: string = '/'): Observable<void> {
    // return this.auth.loginWithRedirect();
    return this.auth.loginWithRedirect({
      client_id: AppConfigService.settings.auth.clientId,
      // organization: 'org_6tyLrBn3k7UC0IPo',
      redirect_uri: `${window.location.origin}`,
      appState: {target: redirectPath},
      scope: 'openid profile email',
      test: 'test',
      entry: document.location.origin,
      upstream_params: {test1: {value: 'test3'}}
    });
  }

  loginWithPopup(redirectPath: string = '/'): void {
    this.auth.loginWithPopup({
      client_id: AppConfigService.settings.auth.clientId,
      // organization: 'org_6tyLrBn3k7UC0IPo',
      redirect_uri: `${window.location.origin}`,
      appState: {target: redirectPath},
      // scope: 'openid profile email',
      // test: 'test',
      // entry: document.location.origin,
      // upstream_params: {test1: {value: 'test3'}}
    }).subscribe(popupLogin => {
        console.log('loginWithPopup', popupLogin);
      },
      // tslint:disable-next-line:no-shadowed-variable
      error => {
        console.log('loginWithPopup error: ', error);
      });
  }

  getAccessToken(): void {
    this.auth.getAccessTokenSilently({
      silent: true
    }).subscribe(token => {
        console.log('getAccessTokenSilently token: ', token);
      },
      // tslint:disable-next-line:no-shadowed-variable
      error => {
        console.log('getAccessTokenSilently error: ', error);
      });
  }

  getAccessTokenP(): void {
    this.auth.getAccessTokenWithPopup({
      silent: true
    }).subscribe(token => {
        console.log('getAccessTokenWithPopup token: ', token);
      },
      // tslint:disable-next-line:no-shadowed-variable
      error => {
        console.log('getAccessTokenWithPopup error: ', error);
      });
  }

  ngOnInit(): void {
    this.userSubscription = this.auth.user$.subscribe(
      (profile) => {
        if (profile) {
          // console.log(profile);
          this.user = profile;
          if (profile.name) {
            this.displayName = profile.name;
          }
        }
      }
    );

    // if (this.autoLogin) {
    //   this.auth.isLoading$.subscribe(loading => {
    //     console.log('Menu ngOnInit loading', loading);
    //     if (!loading) {
    //       this.tenantService.refreshCurrentToken();
    //       this.auth.isAuthenticated$.subscribe(authenticated => {
    //         console.log('Menu ngOnInit authenticated', authenticated);
    //         if (!authenticated) {
    //           console.log('Menu ngOnInit going to login');
    //           // debugger;
    //           // this.loginWithRedirect();
    //         }
    //       });
    //     }
    //   });
    // }
    this.authRequestsService.refreshCurrentToken();
    this.currentTenantSubscription = this.tenantService.tenant$.subscribe(tenant => {
      console.log('Setting tenant -> ', tenant);
      this.currentTenant = tenant;
    });
    this.availableTenantSubscription = this.tenantService.availableTenant$.subscribe(availableTenants => {
      this.availableTenants = availableTenants;
    });
  }

  ngOnDestroy(): void {
    if (this.currentTenantSubscription) {
      this.currentTenantSubscription.unsubscribe();
    }
    if (this.availableTenantSubscription) {
      this.availableTenantSubscription.unsubscribe();
    }
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

}
