<span *ngIf="!myOutlet.isActivated">
   <mat-form-field>
      <mat-label>Filter</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Mia" #input>
   </mat-form-field>
   <div class="mat-elevation-z8">
      <table mat-table [dataSource]="dataSource" matSort>
         <ng-container matColumnDef="questionnaire_completion_uuid">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Completed UUID</th>
            <td mat-cell *matCellDef="let row"> {{row.questionnaire_completion_uuid}} </td>
         </ng-container>
         <ng-container matColumnDef="url">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Link</th>
            <td mat-cell *matCellDef="let row"> <a href="{{row.url}}" target="" _target> Link </a>  </td>
         </ng-container>
         <ng-container matColumnDef="questionnaire_id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Questionnaire ID</th>
            <td mat-cell *matCellDef="let row"> {{row.questionnaire_id}} </td>
         </ng-container>
         <ng-container matColumnDef="completed">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Completed</th>
            <td mat-cell *matCellDef="let row">
              <mat-icon>{{ row.completed ? 'done' : 'warning_amber' }}</mat-icon>
            </td>
         </ng-container>
         <ng-container matColumnDef="client">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Client</th>
            <td mat-cell *matCellDef="let row"> {{row.client}} </td>
         </ng-container>
         <ng-container matColumnDef="created_by">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Created By</th>
            <td mat-cell *matCellDef="let row"> {{row.created_by}} </td>
         </ng-container>

        <ng-container matColumnDef="created_date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Created Date</th>
            <td mat-cell *matCellDef="let row"> {{row.created_date}} </td>
         </ng-container>

         <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
         <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
         <!-- Row shown when there is no matching data. -->
         <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No data matching the filter "{{input["value"]}}"</td>
         </tr>
      </table>
      <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
   </div>
</span>
<router-outlet
   #myOutlet="outlet"
   ></router-outlet>
