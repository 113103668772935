import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatCardModule} from '@angular/material/card';

import {MatFormFieldModule} from '@angular/material/form-field';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatTableModule} from '@angular/material/table';
import {MatInputModule} from '@angular/material/input';
import {MatMenuModule} from '@angular/material/menu';
import {MatTreeModule} from '@angular/material/tree';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MatListModule} from '@angular/material/list';
import {FlexLayoutModule} from '@angular/flex-layout';

import {BasenjiRoutingModule} from './basenji-routing.module';
import {BasenjiHomeComponent} from './components/basenji-home/basenji-home.component';
import {
  HighLevelGraphingModule,
} from '../high-level-graphing/high-level-graphing.module';
import {ReportingComponent} from './components/reporting/reporting.component';
import {ManagementComponent} from './components/management/management.component';
import {LandingComponent} from './components/basenji-home/landing/landing.component';
import {QuestionnaireComponent} from './components/management/questionnaire/questionnaire.component';
import {QuestionComponent} from './components/management/question/question.component';
import {AnswerComponent} from './components/management/answer/answer.component';
import {
  CompletedQuestionnaireComponent
} from './components/reporting/completed-questionnaire/completed-questionnaire.component';
import {MenuComponent} from './components/menu/menu.component';
import {
  QuestionnaireEditComponent
} from './components/management/questionnaire-edit/questionnaire-edit.component';
import {
  ManageSingleQuestionnaireComponent
} from './components/management/manage-single-questionnaire/manage-single-questionnaire.component';

@NgModule({
  declarations: [
    BasenjiHomeComponent,
    ReportingComponent,
    ManagementComponent,
    LandingComponent,
    QuestionnaireComponent,
    QuestionComponent,
    AnswerComponent,
    CompletedQuestionnaireComponent,
    MenuComponent,
    QuestionnaireEditComponent,
    ManageSingleQuestionnaireComponent
  ],
  imports: [
    CommonModule,
    BasenjiRoutingModule,
    MatGridListModule,
    FlexLayoutModule,
    HighLevelGraphingModule,
    MatCardModule,
    ReactiveFormsModule,

    RouterModule,
    MatFormFieldModule,
    MatPaginatorModule,
    MatTableModule,
    MatInputModule,
    MatMenuModule,
    MatTreeModule,
    MatIconModule,
    MatButtonModule,
    MatListModule,

    DragDropModule,
  ]
})
export class BasenjiModule {
  constructor() {
    console.log('BasenjiModule');
  }
}
