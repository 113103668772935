import { Component, OnInit } from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {Validators} from '@angular/forms';
import {FormArray} from '@angular/forms';

@Component({
  selector: 'app-questionnaire-edit',
  templateUrl: './questionnaire-edit.component.html',
  styleUrls: ['./questionnaire-edit.component.css']
})
export class QuestionnaireEditComponent implements OnInit {
  questionnaireForm = this.fb.group({
    name: ['', Validators.required],
    description: [''],
  });

  constructor(private fb: FormBuilder) {
  }

  ngOnInit(): void {
  }

  updateTest(): void {
    this.questionnaireForm.patchValue({
      name: ''
    });
  }

  onSubmit(): boolean {
    // TODO: Use EventEmitter with form value
    console.warn(this.questionnaireForm.value);
    return true;
  }

}
