import {Injectable} from '@angular/core';
import {HttpClient, HttpBackend} from '@angular/common/http';
import {AuthClientConfig, AuthConfig, AuthConfigService} from '@auth0/auth0-angular';
import {config} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {
  static settings: IAppConfig = config;
  httpClient: HttpClient;
  handler: HttpBackend;
  authClientConfig: AuthClientConfig;

  constructor(private http: HttpClient, handler: HttpBackend, authClientConfig: AuthClientConfig) {
    this.httpClient = http;
    this.handler = handler;
    this.authClientConfig = authClientConfig;
    if (AppConfigService.settings) {
      this.authClientConfig.set({
        clientId: '',
        domain: '',
        httpInterceptor: {
          allowedList: [
            {
              uri: '/api/*',
              tokenOptions: {
                audience: 'https://admin.wchsolutions.co.za',
                scope: 'read:current_user'
              }
            }
          ]
        }
      });
    }
  }

  private getConfigURL = (): string => {
    // Getting Parameters for the URL.
    // This includes the product, entry point, and any other tenancy information  we have.
    const variables: any = {};
    variables.entry = window.location.hostname + (window.location.port ? `:${window.location.port}` : '');
    variables.product = 'wch-admin';
    Object.keys(variables).forEach(key => {
      if (!config.configAPIURL.includes('?')) {
        config.configAPIURL = config.configAPIURL + '?';
      } else {
        config.configAPIURL = config.configAPIURL + '&';
      }
      config.configAPIURL = `${config.configAPIURL}${key}=${variables[key]}`;
    });
    return config.configAPIURL;
  };

  load = (): Promise<void> => {
    try {
      const jsonFile = this.getConfigURL();
      return new Promise<void>((resolve, reject) => {
        this.httpClient = new HttpClient(this.handler);
        this.httpClient
          .get(jsonFile)
          .toPromise()
          .then((response: IAppConfig) => {
            AppConfigService.settings = {
              ...(response as IAppConfig),
              ...AppConfigService.settings
            };
            this.authClientConfig.set({
              clientId: AppConfigService.settings.auth.clientId,
              domain: AppConfigService.settings.auth.domain,
            });

            // this.authClientConfig.set({
            //   ...this.authClientConfig,
            //   ...AppConfigService.settings
            // });

            // console.log('Config Loaded');
            console.log(AppConfigService.settings);
            resolve();

          })
          .catch((response: any) => {
            reject(`Could not load the config file`);
            // return {
            //   auth: {clientId: 'TW4TN1K2VpjKI6MAgl3U8dV4C0TMkZY4', domain: 'wchsolutions.eu.auth0.com'},
            //   tenant: {
            //     tenantId: 'wch',
            //     name: 'WCH Solutions (PTY) Ltd',
            //     contactDetails: [{type: 'email', content: 'info@wchsolutions.co.za'}, {
            //       type: 'link',
            //       title: 'Website',
            //       content: 'https://www.wchsolutions.co.za'
            //     }]
            //   }
            // };
          });
      });
    } catch (e) {
      console.log(e);
      console.log('There was an error here');
      // return new Promise<void>((resolve, reject) => {
      //   return {
      //     auth: {clientId: 'TW4TN1K2VpjKI6MAgl3U8dV4C0TMkZY4', domain: 'wchsolutions.eu.auth0.com'},
      //     tenant: {
      //       tenantId: 'wch',
      //       name: 'WCH Solutions (PTY) Ltd',
      //       contactDetails: [{type: 'email', content: 'info@wchsolutions.co.za'}, {
      //         type: 'link',
      //         title: 'Website',
      //         content: 'https://www.wchsolutions.co.za'
      //       }]
      //     }
      //   };
      // });
    }
  };
}

export interface IAppConfig {
  auth?: {
    clientId: string;
    domain: string;
  };
  tenant?: {
    tenantId: string;
    clientId: string;
    contactDetails: [{
      type: 'email' | 'link' | 'logo' | 'phone' | 'text';
      title: string;
      content: string;
      url: string;
    }];
    client: {
      name: string;
      logo: string;
    }
  };
  configAPIURL: string;
  tenantAPIURL: string;
  basenjiAPIURL: string;
  basenjiURL: string;
  beagleAPIURL: string;
  sherlocAPIURL: string;
}
