import {Injectable} from '@angular/core';
import {BehaviorSubject, Subscription} from 'rxjs';
import {Menu} from 'shared/dist/types/Menu';
import {Tenant, TenantService} from '../tenant/tenant.service';
import {AuthRequestsService} from '../auth/auth-requests.service';

@Injectable({
  providedIn: 'platform'
})
export class MenuManagementService {

  private CURRENT_MENU: Menu[];

  private menu = new BehaviorSubject<Menu[]>(null);
  menu$ = this.menu.asObservable();
  private currentTenantServiceSubscription: Subscription;
  private allTenantServiceSubscription: Subscription;
  // private availableTenants: Tenant;
  currentTenant: Tenant;

  setMenu(menu: Menu[]): void {
    this.menu.next(menu);
  }

  mergeMenu(menu: Menu[]): void {
    this.CURRENT_MENU = {
      ...this.menu,
      ...menu
    } as Menu[];
    this.setMenu(this.CURRENT_MENU);
    // this.menu.next();
  }

  appendMenu(newMenu: Menu): void {
    this.CURRENT_MENU.push(newMenu);
    this.setMenu(this.CURRENT_MENU);
  }

  removeMenuAtIndex(index: number): void {
    delete this.CURRENT_MENU[index];
    this.setMenu(this.CURRENT_MENU);
  }

  constructor(private tenantService: TenantService,
              private authRequestsService: AuthRequestsService) {
    this.currentTenantServiceSubscription = this.tenantService.tenant$.subscribe(tenant => {
      if (tenant) {
        this.currentTenant = tenant;
        this.getAndSetMenu();
      }
    });

    // this.allTenantServiceSubscription = this.tenantService.$availableTenant.subscribe(availableTenants => {
    //   this.availableTenants = availableTenants;
    // });

  }

  getAndSetMenu(): void {
    // TODO: Create a service to manage the current TENANT
    this.CURRENT_MENU = [];
    if (this.currentTenant) {
      this.appendMenu({
        icon: '',
        name: 'My Profile',
        path: ['u'],
        type: 'product',
        children: [
          {
            icon: '',
            name: 'Manage',
            path: ['u', 'profile'],
            type: 'product',
          },
          {
            icon: '',
            name: 'Reset Password',
            path: ['u', 'password'],
            type: 'product',
          },
          {
            icon: '',
            name: 'Access',
            path: ['u', 'access'],
            type: 'product',
          }
        ]
      });
      this.appendMenu({
        icon: '',
        name: 'Basenji',
        path: [this.currentTenant.id, 'p', 'basenji'],
        type: 'product',
        children: [
          {
            icon: '',
            name: 'Reports',
            type: 'drop-down',
            path: [this.currentTenant.id, 'p', 'basenji', 'report'],
            children: [
              {
                icon: 'List',
                name: 'Completed Questionnaire',
                link: true,
                path: [this.currentTenant.id, 'p', 'basenji', 'report', 'list'],
                type: 'management',
              },
              {
                icon: 'List',
                name: 'Risk Assessments',
                link: true,
                path: [this.currentTenant.id, 'p', 'basenji', 'report', 'list'],
                type: 'management',
              }
            ]
          },
          {
            icon: '',
            name: 'Manage',
            type: 'drop-down',
            path: [this.currentTenant.id, 'p', 'basenji', 'manage'],
            children: [
              {
                icon: 'List',
                name: 'Completed Questionnaire',
                link: true,
                path: [this.currentTenant.id, 'p', 'basenji', 'manage', 'q'],
                type: 'management',
              }
            ]
          }
        ]
      });
      this.appendMenu({
        icon: '',
        name: 'Sherloc',
        path: [this.currentTenant.id, 'p', 'sherloc'],
        type: 'product'
      });
      this.appendMenu({
        icon: '',
        name: 'Beagle',
        path: [this.currentTenant.id, 'p', 'beagle'],
        type: 'product',
        children: [
          {
            icon: '',
            name: 'diagram',
            type: 'drop-down',
            path: [this.currentTenant.id, 'p', 'beagle', 'r'],
            children: [
              {
                icon: 'summarize',
                name: 'Tree',
                link: false,
                path: [this.currentTenant.id, 'p', 'beagle', 'r', 'tree'],
                type: 'product',
                children: [
                  {
                    icon: 'analytics',
                    name: 'BFL Data',
                    path: [this.currentTenant.id, 'p', 'beagle', 'r', 'tree', 'BFL'],
                    type: 'product',
                    children: [
                      {
                        icon: 'favorite',
                        name: 'BFL with filter',
                        path: [this.currentTenant.id, 'p', 'beagle', 'r', 'tree', 'BFL'],
                        type: 'product',
                        queryParams: {
                          order: 'popular',
                          'price-range': 'not-cheap',
                        },
                      }
                    ],
                  }
                ],
              }
            ]
          },
          {
            icon: '',
            name: 'Risk Profile',
            path: [this.currentTenant.id, 'p', 'beagle', 'r', 'circle-packing'],
            type: 'product',
            link: false,
            children: [
              {
                icon: '',
                name: 'Summarized',
                path: [this.currentTenant.id, 'p', 'beagle', 'r', 'circle-packing', 'shallow'],
                type: 'product',
                link: true,
              },
              {
                icon: '',
                name: 'Detailed',
                path: [this.currentTenant.id, 'p', 'beagle', 'r', 'circle-packing', 'deep'],
                type: 'product',
                link: true,
              },
            ]
          },

        ]
      });
      this.appendMenu({
        icon: '',
        name: 'Whippet',
        path: [this.currentTenant.id, 'p', 'whippet'],
        type: 'product'
      });
      if (this.currentTenant.tenant_type === 'tenant') {
        this.appendMenu({
          icon: '',
          name: 'Administration',
          path: [this.currentTenant.id, 'admin'],
          type: 'management',
          children: [
            {
              icon: '',
              name: 'Users',
              type: 'drop-down',
              path: [this.currentTenant.id, 'p', 'beagle', 'r'],
              children: [
                {
                  icon: 'summarize',
                  name: 'Search',
                  link: true,
                  path: [this.currentTenant.id, 'p', 'beagle', 'r', 'circle-packing'],
                  type: 'management',
                },
                {
                  icon: 'summarize',
                  name: 'List',
                  link: true,
                  path: [this.currentTenant.id, 'p', 'beagle', 'r', 'tree'],
                  type: 'management',
                }
              ]
            },
            {
              icon: '',
              name: 'Roles',
              type: 'drop-down',
              path: [this.currentTenant.id, 'p', 'beagle', 'r'],
              children: [
                {
                  icon: '',
                  name: 'Manage',
                  link: true,
                  path: [this.currentTenant.id, 'p', 'beagle', 'r', 'tree'],
                  type: 'management',
                }
              ]
            },
            {
              icon: '',
              name: 'Clients',
              type: 'drop-down',
              path: [this.currentTenant.id, 'p', 'beagle', 'r'],
              children: [
                {
                  icon: '',
                  name: 'Manage',
                  link: true,
                  path: [this.currentTenant.id, 'p', 'beagle', 'r', 'tree'],
                  type: 'management',
                },
                {
                  icon: '',
                  name: 'Add',
                  link: true,
                  path: [this.currentTenant.id, 'p', 'beagle', 'r', 'tree'],
                  type: 'management',
                },
                {
                  icon: '',
                  name: 'Requests',
                  link: true,
                  path: [this.currentTenant.id, 'p', 'beagle', 'r', 'tree'],
                  type: 'management',
                }
              ]
            },
            {
              icon: '',
              name: 'Products',
              type: 'drop-down',
              path: [this.currentTenant.id, 'p', 'beagle', 'r'],
              children: [
                {
                  icon: '',
                  name: 'Manage',
                  link: true,
                  path: [this.currentTenant.id, 'p', 'beagle', 'r', 'tree'],
                  type: 'management',
                },
                {
                  icon: '',
                  name: 'Add',
                  link: true,
                  path: [this.currentTenant.id, 'p', 'beagle', 'r', 'tree'],
                  type: 'management',
                },
                {
                  icon: '',
                  name: 'Requests',
                  link: true,
                  path: [this.currentTenant.id, 'p', 'beagle', 'r', 'tree'],
                  type: 'management',
                }
              ]
            },
            {
              icon: '',
              name: 'Finance',
              type: 'drop-down',
              path: [this.currentTenant.id, 'p', 'beagle', 'r'],
              children: [
                {
                  icon: '',
                  name: 'Payments',
                  link: true,
                  path: [this.currentTenant.id, 'p', 'beagle', 'r', 'tree'],
                  type: 'management',
                },
                {
                  icon: '',
                  name: 'Transactions',
                  link: true,
                  path: [this.currentTenant.id, 'p', 'beagle', 'r', 'tree'],
                  type: 'management',
                }
              ]
            },
            {
              icon: '',
              name: 'Client Management',
              path: [this.currentTenant.id, 'p', 'whippet'],
              type: 'management',
              children: [
                {
                  icon: '',
                  name: 'My Clients',
                  type: 'drop-down',
                  path: [this.currentTenant.id, 'p', 'beagle', 'r'],
                  children: [
                    {
                      icon: 'summarize',
                      name: 'Search',
                      link: true,
                      path: [this.currentTenant.id, 'p', 'beagle', 'r', 'tree'],
                      type: 'management',
                    },
                    {
                      icon: 'summarize',
                      name: 'List',
                      link: true,
                      path: [this.currentTenant.id, 'p', 'beagle', 'r', 'tree'],
                      type: 'management',
                    }
                  ]
                },
              ]
            }
          ]
        });
        this.appendMenu({
          icon: '',
          name: 'Product Administration',
          path: [this.currentTenant.id, 'admin'],
          type: 'management',
          children: [
            {
              icon: '',
              name: 'Users',
              type: 'drop-down',
              path: [this.currentTenant.id, 'p', 'beagle', 'r'],
              children: [
                {
                  icon: 'summarize',
                  name: 'Search',
                  link: true,
                  path: [this.currentTenant.id, 'p', 'beagle', 'r', 'tree'],
                  type: 'management',
                },
                {
                  icon: 'summarize',
                  name: 'List',
                  link: true,
                  path: [this.currentTenant.id, 'p', 'beagle', 'r', 'tree'],
                  type: 'management',
                }
              ]
            },
            {
              icon: '',
              name: 'Roles',
              type: 'drop-down',
              path: [this.currentTenant.id, 'p', 'beagle', 'r'],
              children: [
                {
                  icon: '',
                  name: 'Manage',
                  link: true,
                  path: [this.currentTenant.id, 'p', 'beagle', 'r', 'tree'],
                  type: 'management',
                }
              ]
            },
            {
              icon: '',
              name: 'Clients',
              type: 'drop-down',
              path: [this.currentTenant.id, 'p', 'beagle', 'r'],
              children: [
                {
                  icon: '',
                  name: 'Manage',
                  link: true,
                  path: [this.currentTenant.id, 'p', 'beagle', 'r', 'tree'],
                  type: 'management',
                },
                {
                  icon: '',
                  name: 'Add',
                  link: true,
                  path: [this.currentTenant.id, 'p', 'beagle', 'r', 'tree'],
                  type: 'management',
                },
                {
                  icon: '',
                  name: 'Requests',
                  link: true,
                  path: [this.currentTenant.id, 'p', 'beagle', 'r', 'tree'],
                  type: 'management',
                }
              ]
            },
            {
              icon: '',
              name: 'Products',
              type: 'drop-down',
              path: [this.currentTenant.id, 'p', 'beagle', 'r'],
              children: [
                {
                  icon: '',
                  name: 'Manage',
                  link: true,
                  path: [this.currentTenant.id, 'p', 'beagle', 'r', 'tree'],
                  type: 'management',
                },
                {
                  icon: '',
                  name: 'Add',
                  link: true,
                  path: [this.currentTenant.id, 'p', 'beagle', 'r', 'tree'],
                  type: 'management',
                },
                {
                  icon: '',
                  name: 'URL Management',
                  link: true,
                  path: [this.currentTenant.id, 'p', 'beagle', 'r', 'tree'],
                  type: 'management',
                },
                {
                  icon: '',
                  name: 'Authentication and Authorization',
                  link: true,
                  path: [this.currentTenant.id, 'p', 'beagle', 'r', 'tree'],
                  type: 'management',
                  children: [
                    {
                      icon: '',
                      name: 'Auth0',
                      link: true,
                      path: [this.currentTenant.id, 'p', 'beagle', 'r', 'tree'],
                      type: 'management',
                    },
                  ]
                }
              ]
            },
            {
              icon: '',
              name: 'Finance',
              type: 'drop-down',
              path: [this.currentTenant.id, 'p', 'beagle', 'r'],
              children: [
                {
                  icon: '',
                  name: 'Payments',
                  link: true,
                  path: [this.currentTenant.id, 'p', 'beagle', 'r', 'tree'],
                  type: 'management',
                },
                {
                  icon: '',
                  name: 'Transactions',
                  link: true,
                  path: [this.currentTenant.id, 'p', 'beagle', 'r', 'tree'],
                  type: 'management',
                }
              ]
            },
            {
              icon: '',
              name: 'Product Roles',
              type: 'drop-down',
              path: [this.currentTenant.id, 'p', 'beagle', 'r'],
              children: [
                {
                  icon: '',
                  name: 'Manage',
                  link: true,
                  path: [this.currentTenant.id, 'p', 'beagle', 'r', 'tree'],
                  type: 'management',
                }
              ]
            },
            {
              icon: '',
              name: 'Events',
              path: [this.currentTenant.id, 'p', 'whippet'],
              type: 'management',
            }
          ]
        });
      }
    }

  }

}
