import {Component, OnDestroy, OnInit} from '@angular/core';
import {MenuManagementService} from '../../../../shared/services/menu/menu-management.service';
import {Subscription} from 'rxjs';
import {Menu} from 'shared/dist/types/Menu';

@Component({
  selector: 'app-side-navigation',
  templateUrl: './side-navigation.component.html',
  styleUrls: ['./side-navigation.component.css']
})
export class SideNavigationComponent implements OnInit, OnDestroy {
  tenantId = 'WCH';

  products = [
    {
      name: 'Basenji',
      id: 'basenji',
      subProducts: [
        {
          name: 'test1',
          path: [],
        }
      ]
    },
    {
      name: 'Sherloc',
      id: 'sherloc',
      subProducts: [
        {
          name: 'test1',
          path: [],
        }
      ]
    },
    {
      name: 'Beagle',
      id: 'beagle',
      subProducts: [
        {
          name: 'test1',
          path: [],
        }
      ]
    },
    {
      name: 'Whippet',
      id: 'whippet',
      subProducts: [
        {
          name: 'test1',
          path: [],
        }]
    }
  ];
  users = false;
  clients = false;
  subscription = false;
  private menuSubscription: Subscription;
  navigation: Menu[];

  constructor(private menuManagementService: MenuManagementService) {
  }

  ngOnInit(): void {
    //     this.gameInPlaySubscription = this.men.gameInPlay$.subscribe(gameInPlay => {
    //   // console.log('gameInPlaySubscription', gameInPlay);
    //   this.gameInPlay = gameInPlay;
    // });

    this.menuSubscription = this.menuManagementService.menu$.subscribe(newMenu => {
      this.navigation = newMenu;
    });
  }

  ngOnDestroy(): void {
    this.menuSubscription.unsubscribe();
  }

}
