// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
};

export const config = {
  // auth: {
  //   domain: 'wchsolutions-dev.eu.auth0.com',
  //   clientId: 'LEQR4HSqya7jvkBYvPJxFz0aCMzJHKiA',
  // },
  configAPIURL: `https://api.admin.dev.wchsolutions.co.za/ui/config`,
  tenantAPIURL: `https://api.tenant.dev.wchsolutions.co.za`,

  basenjiAPIURL: `https://api.basenji.dev.wchsolutions.co.za`,

  beagleAPIURL: `https://api.beagle.dev.wchsolutions.co.za`,
  sherlocAPIURL: `https://api.sherloc.dev.wchsolutions.co.za`,

  basenjiURL: `https://oqa.dev.wchsolutions.co.za`,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
