import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ErrorComponent} from '../../components/error/error.component';
import {BasenjiHomeComponent} from './components/basenji-home/basenji-home.component';
import {AuthGuard} from '@auth0/auth0-angular';
import {ManagementComponent} from './components/management/management.component';
import {ReportingComponent} from './components/reporting/reporting.component';
import {QuestionnaireComponent} from './components/management/questionnaire/questionnaire.component';
import {QuestionComponent} from './components/management/question/question.component';
import {AnswerComponent} from './components/management/answer/answer.component';
import {MenuComponent} from './components/menu/menu.component';
import {QuestionnaireEditComponent} from './components/management/questionnaire-edit/questionnaire-edit.component';
// tslint:disable-next-line:max-line-length
import {ManageSingleQuestionnaireComponent} from './components/management/manage-single-questionnaire/manage-single-questionnaire.component';

const routes: Routes = [
    {
      path: '',
      component: MenuComponent,
      children: [
        {
          path: 'report',
          canActivate: [AuthGuard],
          component: ReportingComponent,
          children: [
            {
              path: 'q',
              component: BasenjiHomeComponent,
              canActivate: [AuthGuard],
              children: [
                {
                  path: ':completed_questionnaire_uuid',
                  component: BasenjiHomeComponent
                }
              ]
            },
            {
              // This will list completed questionnaires for the specified tenant
              path: 'list',
              canActivate: [AuthGuard],
              component: BasenjiHomeComponent,
            },
          ]
        },
        {
          path: 'manage',
          canActivate: [AuthGuard],
          component: ManagementComponent,
          children: [
            {
              path: 'q',
              // component: QuestionnaireComponent,
              canActivate: [AuthGuard],
              // redirectTo: 'q/view',
              children: [
                {
                  path: ':questionnaire_id',
                  component: ManageSingleQuestionnaireComponent,
                  children: [
                    {
                      // This should not take you anywhere
                      path: 'question',
                      component: QuestionComponent,
                      children: [
                        {
                          path: ':question_id',
                          children: [
                            {
                              // This can be a modal in the question
                              path: 'answer',
                              children: [
                                {
                                  path: ':answer_id',
                                  component: AnswerComponent,
                                  // This path looks something like this
                                  // /manage/q/4/question/96/answer/504
                                },
                              ]
                              // component: BasenjiHomeComponent,
                            },
                          ]
                        },
                      ]
                    },
                    {
                      path: 'edit',
                      component: QuestionnaireEditComponent,
                    }
                  ]
                },
                {
                  path: '',
                  component: QuestionnaireComponent,
                },
              ]
            },
          ],
        },
        {
          path: 'home',
          component: BasenjiHomeComponent,
          pathMatch: 'full',
        },
        {
          path: '',
          // component: BasenjiHomeComponent,
          pathMatch: 'full',
          redirectTo: 'home'
        },
      ]
    },
  ]
;

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BasenjiRoutingModule {
  constructor() {
    console.log('BasenjiRoutingModule');
  }
}
